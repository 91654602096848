import React, { Suspense, lazy } from 'react'
import './App.css';
// import Bitcoin from './component/Bitcoin';
// import Footer from './component/Footer';
// import Hosts from './component/Hosts';
// import Navbar from './component/Navbar';
// import Participate from './component/Participate';
// import PrizeDistribute from './component/PrizeDistribute';
// import ScrollButton from './component/ScrollButton';
// import Fortune from './component/Fortune';
// import Faqs from './component/Faqs';

const Navbar = lazy(() => import('./component/Navbar'));
const Fortune = lazy(() => import('./component/Fortune'));
const Bitcoin = lazy(() => import('./component/Bitcoin'));
const PrizeDistribute = lazy(() => import('./component/PrizeDistribute'));
const Participate = lazy(() => import('./component/Participate'));
const Hosts = lazy(() => import('./component/Hosts'));
const Faqs = lazy(() => import('./component/Faqs'));
const Footer = lazy(() => import('./component/Footer'));
const ScrollButton = lazy(() => import('./component/ScrollButton'));

function App() {

  return (
    <Suspense>
      <div className="">
        <Navbar />
        <Fortune />
        <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-smooth-scroll="true" data-bs-root-margin="0px 0px -10%" tabIndex="0"  >
          <section id="how-it-works">
            <Bitcoin />
          </section>
          <section id="prize-distribute">
            <PrizeDistribute />
          </section>
          <section id='referral'>
            <Participate />
          </section>
          <section id='hosts'>
            <Hosts />
          </section>
          <section id='faqs'>
            <Faqs />
          </section>
        </div>
        <Footer />
        <ScrollButton />
      </div>
    </Suspense>
  );
}

export default App;
